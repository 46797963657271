define("discourse/plugins/discourse-user-tag/discourse/templates/connectors/user-preferences-profile/user-tag-preferences", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="control-label">{{i18n "user_tags.preferences.title"}}</label>
  
  {{this.currentUser.user_tags}}
  
  
  */
  {
    "id": "0zgWcP6V",
    "block": "[[[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"user_tags.preferences.title\"],null]],[13],[1,\"\\n\\n\"],[1,[30,0,[\"currentUser\",\"user_tags\"]]],[1,\"\\n\\n\"]],[],false,[\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-user-tag/discourse/templates/connectors/user-preferences-profile/user-tag-preferences.hbs",
    "isStrictMode": false
  });
});