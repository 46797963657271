define("discourse/plugins/discourse-user-tag/discourse/templates/connectors/topic-category/user-tag-display", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.topic.tag_owners}}
    {{i18n "user_tags.prefix"}}
    {{#each this.topic.tag_owners as |username|}}
      <a href="/u/{{username}}" data-user-card={{username}}>
        {{avatar username imageSize="small"}}{{username}}
      </a>
    {{/each}}
  {{/if}}
  
  */
  {
    "id": "eLhbOoSI",
    "block": "[[[41,[30,0,[\"topic\",\"tag_owners\"]],[[[1,\"  \"],[1,[28,[35,1],[\"user_tags.prefix\"],null]],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"topic\",\"tag_owners\"]]],null]],null],null,[[[1,\"    \"],[10,3],[15,6,[29,[\"/u/\",[30,1]]]],[15,\"data-user-card\",[30,1]],[12],[1,\"\\n      \"],[1,[28,[35,4],[[30,1]],[[\"imageSize\"],[\"small\"]]]],[1,[30,1]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null]],[]],null]],[\"username\"],false,[\"if\",\"i18n\",\"each\",\"-track-array\",\"avatar\"]]",
    "moduleName": "discourse/plugins/discourse-user-tag/discourse/templates/connectors/topic-category/user-tag-display.hbs",
    "isStrictMode": false
  });
});