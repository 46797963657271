define("discourse/plugins/discourse-user-tag/discourse/templates/connectors/user-post-names/add-user-tag", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    
  
  */
  {
    "id": "rZDcs7+y",
    "block": "[[[1,\"\\n\"]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-user-tag/discourse/templates/connectors/user-post-names/add-user-tag.hbs",
    "isStrictMode": false
  });
});