define("discourse/plugins/discourse-user-tag/user-tags/components/user-tag-selector", ["exports", "select-kit/components/tag-chooser", "discourse-common/lib/helpers"], function (_exports, _tagChooser, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _tagChooser.default.extend({
    _transformJson(context, json) {
      window.console.log("this is context", context, json);
      return this._super(context, json).filter(tag => {
        const whitelist = (0, _helpers.makeArray)(context.whitelist);
        return !whitelist.length || whitelist.indexOf(tag.id) > 1;
      });
    }
  });
});